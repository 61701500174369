.privacy{
display: flex;
align-items: center;
flex-direction: column;
padding-top: 30px;
padding-bottom: 30px;
background-color: white;

    .title{
        margin-top: 0;
        margin-bottom: 25px;
        font-size: 190%;
        font-weight: 900;
        color: black;
    }
    .text{
        text-align: center;
        margin: 0;
        font-size: 110%;
        font-weight: 500;
        color: black;

    }
    .link{
        font-size: 110%;
        font-weight: 500;
    }
}