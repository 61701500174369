@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

 





.container{
    width: 100%;
    max-width: 480px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
}

button{
    font-family: inherit;

}

.custom_button{
    font-family: 'Montserrat';
}



body{
    font-family: 'Montserrat', sans-serif;
}