@import "../../scss/colors.scss";

.games_container {
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding-top: 100px;
  background-size: cover;
  padding-bottom: 80px;
  height: 100vh;
  overflow: hidden;

  .title{
    background-image: linear-gradient(180deg, #FFA800 0%, #FFE600 100%); 
    color: transparent; 
    -webkit-background-clip: text; 
    background-clip: text; 
    font-size: 35px; 
    font-weight: 900;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  
.level_container {
    position:absolute ;
    max-width: 460px;
    width: 100%;
    height: 60px;
    padding:  8px 20px;
    border-radius: 40px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    top: 20px;
    z-index: 20;
    box-sizing: border-box;

    .balance{
      color: getColor("text-color");
      font-size: 16px;
      font-weight: 100;
    }
    .balance_number{
      color: getColor("text-color");
      font-size: 20px;
      font-weight: 900;
    }
    

   .level_items{
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 50px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 150px;

    
    .text{
      color: getColor("text-color");
      font-size: 16px;
      font-weight: 100;

  }
  .number{
   
    color: getColor("text-color");
    font-size: 20px;
    font-weight: 900;
  }
   }

  }
    .carusel_item {
      position: relative;
      overflow: hidden;
      width: 65%;
    height: 160px;
    margin-right: 10px;
    background-size: cover;
    border-radius: 30px;
    border: solid 2px #FFF124;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    


.lock_wrap{
  background: #31313196;

  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  align-items: end;

  .lock_item{
    display: flex;
    align-items: end;
    justify-content: end;
    .lock{
      width: 40%;

    }
    
  
}


      
    }
  }



  .flicking-viewport{
      width: 100%;
  }

  .man{
    width: 46%;
    position: absolute;
    bottom: 0%;
    right: 0%;
  }

  .custom_button{
    width: 70%;
    z-index: 1;
    position: absolute;
    bottom: 0%;
    left: 0%;
    background: transparent;
    border: none;
    padding: 0;
    display: flex;
    align-items: baseline;
    & :focus-visible{
      border: none;
      outline: none;
    }
    .button_img{
      display: block;
      width: 100%;
    }
  }

  
}








.island_game{
  width: 70%;
  position: absolute;
  top: -28%;
  transform: rotateZ(0deg);
  right: 10%;
}
.shadow_display{
  display: none;
  background-color: #000;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 0;
 }
 .shadow_display.active{
  display: flex;
 }
 .active .text{
  color: white;
  font-size: 130%;
  font-weight: 700;
 }

 .buton_wrap{
  position: absolute;
  top: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 12%;
  height: 17%;
  left: 5%;

  .custom_button{
    position: relative;
    width: 100%;
    .custom_button_image{
      width: 100%;
    }
  }
 }