@import "../../scss/colors.scss";

.menue_container{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    position: relative;

    .dino_img{
        max-width: 450px;
        position: absolute;
        z-index: 0;
        width: 100%;
        right: 0;
        bottom: 0;

    }


    .button_wrap{
    position: relative;

        z-index: 10;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 10px;
        flex-direction: column;
        height: 70%;
        justify-content: space-evenly;


        .custom_button{
            width: 100%;
            padding: 19px 15px;
            font-size: 30px;
            color: getColor("btn-text");
            font-weight: 700;
            border: none;
            background-color: transparent;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            height: 18%;
        
        }
    }
}